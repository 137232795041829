import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/accessories/Navbar';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import Footer from './components/accessories/Footer';

function App() {
  return (
    <div className="App">
       <Navbar/>
       <Home/>
       <Services/>
       <Contact/>
       <Footer/>
    </div>
  );
}

export default App;
