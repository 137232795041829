import styled from "styled-components";
import React from "react";
import EmailIcon from "../svg/EmailIcon";
import PhoneIcon from "../svg/PhoneIcon";

export default function Contact() {
  const companyPhoneNumber = "403-539-3777";
  const companyEmailAddress = "info@clickats.com";

  const handleCallClick = () => {
    window.location.href = `tel:${companyPhoneNumber}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${companyEmailAddress}`;
  };

  return (
    <StyledContact id="contact">
      <div className="image-left">
        {/* <ContactIcon /> */}
      </div>
      <div>
        <h1> Contact Us</h1>
        <div className="contact-main">
          <div className="contact-description">
            <h2>File your Taxes From Home with a Tax Expert !</h2>
            <h2></h2>
            <p>
              Call or email to book a <b>FREE</b> 30 minute consultation now!
            </p>
            <p>No contract commitment! No hidden fees!</p>
          </div>

          <div className="contact-items section">
            <div className="phone contact-button" onClick={handleCallClick}>
              <PhoneIcon className="white-svg" />

              <span className="contact-details">+1 (403) 539 3777</span>
            </div>

            <div className="email contact-button" onClick={handleEmailClick}>
              <EmailIcon />

              <span className="contact-details">info@clickats.com</span>
            </div>
          </div>
        </div>
      </div>
    </StyledContact>
  );
}

// function ContactItem(props) {
//   return (
//     <>

//     </>

//     //   <div class="card">
//     //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
//     //       <path d="M20 5H4V19L13.2923 9.70649C13.6828 9.31595 14.3159 9.31591 14.7065 9.70641L20 15.0104V5ZM2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path>
//     //     </svg>
//     //     <div class="card__content">
//     //       <p class="card__title">Card Title</p>
//     //       <p class="card__description">
//     //         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//     //         eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//     //         ad minim veniam, quis nostrud exercitation ullamco.
//     //       </p>
//     //     </div>
//     //   </div>
//   );
// }

const StyledContact = styled.div`
  /* display: flex;
  justify-content: center; */

  .contact-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .image-left svg {
    width: 70%;
    height: 70%;
  } */


  .contact-items svg {
    fill: white;
    margin-right: 1rem;
    width: 30px;
    height: 30px;
  }

  h1 {
    font-size: 2rem;
    padding: 2rem;
    text-align: center;
    color: #24441e;
  }

  .contact-description {
    background-color: #6eab2c20;
    /* box-shadow: 1px 1px 3px #6eab2c;     */
    border-radius: 51% 49% 45% 55% / 25% 19% 81% 75%;
    margin: 1rem;
    padding: 3rem;
  }

  .section {
    margin-bottom: 1.5rem;
    /* padding: 2rem; */
    border-radius: 20px;
  }

  .contact-description {
    max-width: 500px;
    margin-top: 2rem;
    text-align: center;
  }

  .contact-description h2 {
    font-size: 1.5rem;
  }

  .contact-button {
    background-color: #6eab2c;
    color: white;
    min-width: 300px;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 300ms ease;
  }

  .contact-button:hover {
    background-color: #24441e;
  }

  /* .contact-button .text {
    display: none;
  }

  .contact-button:hover > .text {
    display: block;
  }

  .contact-button:hover > svg {
    display: none;
  }

  .contact-button:hover {
    background-color: rgb(162, 63, 255);
    color: #fff;
  }

  .contact-button svg {
    width: 1.5em;
    margin-top: 3px;
  } */

  .contact-details {
    width: 80%;
    /* border: solid black 1px; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .contact-details p {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 1000px) {
    .contact-button {
      font-size: 1rem;
      min-width: 500px;
    }
  }

  
`;
