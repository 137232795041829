import styled from "styled-components";
import React, { useState } from "react";
import logo from "../../images/logo.png";
import { Link } from "react-scroll";

export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);

  const handleNavbarClick = () => {
    setNavOpen(false);
  };

  return (
    <StyledNavbar>
      <header>
        <Link to="home" spy={true} offset={-100} duration={200}>
          <img src={logo} className="logo" alt="logo"></img>
        </Link>

        <input
          type="checkbox"
          id="nav-toggle"
          className="nav-toggle"
          checked={navOpen}
          onChange={() => setNavOpen(!navOpen)}
        ></input>
        <nav>
          <ul>
            <li>
              <Link
                className="Link"
                to="home"
                spy={true}
                offset={-100}
                duration={200}
                onClick={handleNavbarClick}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="Link"
                to="services"
                spy={true}
                offset={-100}
                duration={200}
                onClick={handleNavbarClick}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                className="Link"
                to="contact"
                spy={true}
                offset={-100}
                duration={200}
                onClick={handleNavbarClick}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
        <label for="nav-toggle" className="nav-toggle-label">
          <span></span>
        </label>
      </header>
    </StyledNavbar>
  );
}

const StyledNavbar = styled.header`
user-select: text;

  header {
    /* background-color: #fffef2; */
    background-color: #fffffa;
    text-align: center;
    position: fixed;
    z-index: 999;
    width: 100%;
  }

  .logo {
    margin: 1rem;
    width: 75px;
    height: 75px;
  }

  .nav-toggle {
    display: none;
  }

  .nav-toggle-label span,
  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    background: black;
    display: block;
    height: 2px;
    width: 2em;
    border-radius: 2px;
    position: relative;
  }

  .nav-toggle-label span::before,
  .nav-toggle-label span::after {
    content: "";
    position: absolute;
  }

  .nav-toggle-label span::before {
    bottom: 7px;
  }

  .nav-toggle-label span::after {
    top: 7px;
  }

  .nav-toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display: flex;
    align-items: center;
  }

  nav {
    /* background-color: #fffef2; */
    position: absolute;
    text-align: left;
    left: 0;
    width: 100%;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 400ms ease-in-out;
  }

  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  nav li {
    margin-bottom: 1em;
    margin-left: 1em;
  }

  nav a {
    color: black;
    text-decoration: none;
    font-size: 1.2rem;
    /* text-transform: uppercase; */
    opacity: 0;
    /* transition: opacity 100ms ease-in-out; */
  }

  nav a:hover {
    color: #6eab2c;
    transition: color 150ms;
  }

  .nav-toggle:checked ~ nav {
    background-color: #fffffa;
    transform: scale(1, 1);
    transition: transform 400ms ease-in-out;
  }

  .nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
  }

  @media screen and (min-width: 800px) {
    header {
      display: grid;
      grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
    }

    .nav-toggle-label {
      display: none;
    }

    .logo {
      margin: 1.5rem;
      width: 70px;
      height: 70px;
      grid-column: 2/3;
    }

    nav {
      margin: 1.5rem;
      all: unset;
      grid-column: 3/4;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    nav ul {
      display: flex;
      justify-content: flex-end;
    }

    nav li {
      margin-left: 3em;
      margin-bottom: 0;
    }

    nav a {
      opacity: 1;
    }

    .contact {
      color: white;
      background-color: #6eab2c;
      border-radius: 10px;
      transition: color, background-color 200ms ease-in-out;
    }

    .contact:hover {
      color: #6eab2c;
      background-color: black;
    }
  }
`;

// export default function NavBar() {
//   const [isActive, setIsActive] = useState(false);

//   const toggleMenu = () => {
//     setIsActive(!isActive);
//   };

//   return (
//     <StyledNavbar>
//       <nav className="navbar">
//         <div className="brand">
//             <img src={logo} alt='logo'></img>
//             {/* <p>ClickATS</p> */}
//         </div>
//         <div
//           className={`toggle-button ${isActive ? "active" : ""}`}
//           onClick={toggleMenu}
//         >
//           <span className="bar"></span>
//           <span className="bar"></span>
//           <span className="bar"></span>
//         </div>
//         <div className={`navbar-links ${isActive ? "active" : ""}`}>
//           <ul>
//             <li href="#">Home</li>
//             <li href="#">Our Services</li>
//             <button href="#" className="contact-button">
//               Contact Us
//             </button>
//           </ul>
//         </div>
//       </nav>
//     </StyledNavbar>
//   );
// }

// const StyledNavbar = styled.div`

//   .navbar {
//     background-color: red;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     padding: 2rem 2rem;
//     color: black;
//   }

//   .brand {
//     font-size: 1.5rem;
//   }

//   .brand img {
//     width: 60px;
//     height: 60px;
//     /* display: none; */
//   }

//   .navbar-links ul {
//     margin: 0 1rem;
//     padding: 0;
//     display: flex;
//   }

//   .navbar-links li {
//     border-radius: 10px;
//     color: black;
//     list-style: none;
//     margin: 0 1rem;
//     padding: 0.5rem 1rem;
//     transition: color 250ms;
//   }

//   .navbar-links li:hover {
//     color: #6eab2c;
//   }

//   .toggle-button {
//     /* position: absolute;
//     top: 1.5rem;
//     right: 1rem; */
//     display: none;
//     flex-direction: column;
//     justify-content: space-between;
//     width: 30px;
//     height: 20px;
//     transition: display 2s;
//   }

//   .toggle-button .bar {
//     height: 3px;
//     width: 100%;
//     background-color: black;
//     border-radius: 10px;
//   }

//   @media (max-width: 700px) {
//     .toggle-button {
//       display: flex;
//     }

//     .navbar-links {
//       display: none;
//       width: 100%;
//     }

//     /* .navbar {
//       flex-direction: column;
//       align-items: flex-start;
//     } */

//     .navbar-links ul {
//       flex-direction: column;
//       width: 100%;
//     }

//     .navbar-links li {
//       text-align: center;
//       padding: 0.5rem 1rem;
//     }

//     .navbar-links.active {
//       display: flex;
//     }
//   }

//   .contact-button {
//     border-radius: 10px;
//     background-color: #6eab2c;
//     color: black;
//     font-weight: 600;
//     padding: 0 1rem;
//     transition: color, background-color 500ms;
//   }

//   .contact-button:hover {
//     background-color: black;
//     color: #6eab2c;
//   }
// `;
