import styled from "styled-components";
import CheckmarkIcon from "../svg/CheckmarkIcon";
import IndividualIcon from "../svg/IndividualIcon";
import CorporationIcon from "../svg/CorporationsIcon";

export default function Services() {
  return (
    <StyledServices id="services">
      <div className="services-intro section">
        <h1>Our Services</h1>
        <h5>Comprehensive Accounting and Tax Services – Delivered Remotely</h5>
        <p>
          At CLICK Accounting &amp; Tax Services, we understand that every
          client’s financial needs are unique. That’s why we offer a wide range
          of services tailored to meet the specific requirements of both
          individuals and businesses. Our focus is on providing a seamless,
          convenient experience through fully remote service delivery, ensuring
          you have access to expert financial assistance from wherever you are.{" "}
        </p>
        <p>
          Maintaining accurate and up-to-date financial records is essential for
          both businesses and individuals. Our bookkeeping services help you
          stay on top of your finances by managing your daily transactions,
          organizing financial documents, and preparing detailed reports. By
          offering these services remotely, we ensure that your books are always
          in order, freeing up your time to focus on your core activities.
        </p>

        <p>
          We offer full accounting services, including financial statement
          preparation, general ledger maintenance, and expense tracking. Whether
          you&#39;re a small business looking for regular financial oversight or
          an individual managing personal finances, our accounting solutions are
          customized to meet your needs. With our remote service model, you can
          access your financial information at any time and stay on track with
          your financial goals.
        </p>

        <p>
          Tax season can be stressful, but with our expert tax preparation and
          filing services, you can rest easy knowing your returns are in safe
          hands. We handle both personal and business tax filings, ensuring
          compliance with all applicable tax laws and minimizing your tax bill
          by maximizing your deductions and credits. Our remote platform allows
          us to gather the necessary information and file your taxes
          electronically, saving you time and hassle.
        </p>
      </div>

      <div className="services-description">
        <div className="corporations section">
          <div className="corporations-list">
            <h2>Services for Corporations</h2>
            <ul>
              <li>
                <CheckmarkIcon />
                Corporation registration
              </li>
              <li>
                <CheckmarkIcon />
                Bookkeeping and Document Management
              </li>
              <li>
                <CheckmarkIcon />
                Payroll
              </li>
              <li>
                <CheckmarkIcon />
                GST/HST Returns
              </li>
              <li>
                <CheckmarkIcon />
                Tax Filing
              </li>
              <li>
                <CheckmarkIcon />
                Year end review, analyses and adjustments
              </li>
              <li>
                <CheckmarkIcon />
                Financial Statements
              </li>
              <li>
                <CheckmarkIcon />
                Business Consulting
              </li>
              <li>
                <CheckmarkIcon />
                Business Plan Drafting
              </li>
              <li>
                <CheckmarkIcon />
                Other business support services as needed
              </li>
            </ul>
          </div>
          <div className="corporations-picture">
            <CorporationIcon className="service-icons" />
          </div>
        </div>

        <div className="individuals section">
          <div className="individuals-list">
            <h2>Services for Individuals</h2>
            <ul>
              <li>
                <CheckmarkIcon />
                Personal Income Tax Filing
              </li>
              <li>
                <CheckmarkIcon />
                Sole Proprietorship registration
              </li>
              <li>
                <CheckmarkIcon />
                Bookkeeping and Document Management
              </li>
              <li>
                <CheckmarkIcon />
                Payroll
              </li>
              <li>
                <CheckmarkIcon />
                GST/HST Return
              </li>
              <li>
                <CheckmarkIcon />
                Year end review, analyses and adjustments
              </li>
              <li>
                <CheckmarkIcon />
                Business Consulting
              </li>
              <li>
                <CheckmarkIcon />
                Business Plan Drafting
              </li>
              <li>
                <CheckmarkIcon />
                Other support services as needed
              </li>
            </ul>
          </div>
          <div className="individuals-picture">
            <IndividualIcon className="service-icons" />
          </div>
        </div>
      </div>
    </StyledServices>
  );
}

const StyledServices = styled.div`
  border-radius: 1rem;
  /* padding: 1rem; */
  margin-top: 5rem;
  /* color: white; */
  /* background-color: #6eab2c; */

  h5 {
    margin-bottom: 1.5rem;
  }

  ul {
    list-style: none;
  }

  ul li {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  /* Add this CSS to set a fixed height for the containers */
  .corporations-list,
  .individuals-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items at the top */
    height: 100%; /* Set a fixed height to ensure alignment */
  }

  h1 {
    font-size: 2rem;
    color: #24441e;
    margin-bottom: 2rem;
    /* padding-top: 5rem; */
  }

  h2 {
    font-size: 1.5rem;
  }

  .services-intro {
    background-color: #6eab2c20;
    border-radius: 20px 20px 0 0;
  }

  .services-description {
    padding-top: 2rem;
    margin-bottom: 5rem;
    position: relative;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    background-color: #6eab2c20;
    border-bottom-right-radius: 100% 100%;
    /* border-radius: 30px; */
  }

  .section {
    /* margin-bottom: 2rem; */
    padding: 2rem;
    /* border-radius: 20px; */
  }

  .corporations-picture,
  .individuals-picture {
    display: none;
  }

  .corporations,
  .individuals {
    color: white;
    position: relative;
    isolation: isolate;
    /* background-color: #6eab2c; */
  }

  .corporations::after,
  .individuals::after {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    transform: skewY(-2deg);
  }

  .corporations::after {
    background-color: #24441e;
  }

  .individuals::after {
    background-color: #6eab2c;
  }

  @media screen and (min-width: 450px) {
    .corporations,
    .individuals {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  @media screen and (min-width: 1000px) {
    h2 {
      font-size: 2rem;
    }

    .services-description {
      flex-direction: row;
    }

    .individuals::after {
      transform: skewY(2deg);
    }

    .section {
      padding: 5rem;
    }

    .services-intro {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    .individuals,
    .corporations {
      width: 50%;
    }
  }

  @media screen and (min-width: 1500px) {
    .corporations-picture,
    .individuals-picture {
      display: block;
    }
  }
`;
