import React from "react";
import styled from "styled-components";
// import FacebookIcon from "../svg/FacebookIcon";
// import EmailIcon from "../svg/EmailIcon";
// import InstagramIcon from "../svg/InstagramIcon";
// import WhatsappIcon from "../svg/WhatsappIcon";
// import YoutubeIcon from "../svg/YoutubeIcon";
// import PhoneIcon from "../svg/PhoneIcon";

export default function Footer() {
  return (
    <StyledFooter>
      {/* <div className="socials">
        <FacebookIcon/>
        <EmailIcon />
        <InstagramIcon />
        <WhatsappIcon />
        <YoutubeIcon />
        <PhoneIcon />
      </div>
    <div className='nav'>

    </div> */}
      <div className="footter-bottom">
        <p>&copy; 2024 CLICK Accounting & Tax Services</p>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
  /* height: 50px; */
  color: white;
  background-color: #24441e;




  svg {
    width: 40px;
    height: 40px;
  }

  .socials {
    display: flex;
    gap: 10px;
  }
`;
