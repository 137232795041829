import Accounting from "../../images/accounting_illustration.png";
import styled from "styled-components";
import { Link } from "react-scroll";
import EmailIcon from "../svg/EmailIcon";
import PhoneIcon from "../svg/PhoneIcon";

export default function Home() {
  const companyPhoneNumber = "403-539-3777";
  const companyEmailAddress = "info@clickats.com";

  const handleCallClick = () => {
    window.location.href = `tel:${companyPhoneNumber}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${companyEmailAddress}`;
  };

  return (
    <StyledHome id="home" className="home">
      <div className="text-left">
        <h1>CLICK</h1>
        <h2>Accounting and Tax Services</h2>
        <p>
          At CLICK Accounting &amp; Tax Services, we are committed to helping
          you navigate your bookkeeping, accounting, and tax responsibilities
          with ease and convenience. Offering flexible, fully remote services,
          we ensure you can manage your financial obligations from the comfort
          of your home or office.
        </p>
        <div className="get-started">
          <p>
            Contact us to book a one-time<br></br>
            <b>FREE</b> 30 minute consultation!
          </p>
          <div>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-100}
              duration={200}
              className="contact-button"
            >
              Book Now
            </Link>
          </div>
        </div>
        <div className="contact-items section">
          <div className="btn phone contact-button" onClick={handleCallClick}>
            <PhoneIcon className="white-svg" />

            <span className="contact-details">+1 (403) 539 3777</span>
          </div>

          <div className="btn email contact-button" onClick={handleEmailClick}>
            <EmailIcon />

            <span className="contact-details">info@clickats.com</span>
          </div>
        </div>
      </div>
      <div className="image-right">
        <img src={Accounting} alt=""></img>
      </div>
    </StyledHome>
  );
}

const StyledHome = styled.div`
  padding-top: 10rem;
  min-height: 85vh;

  .btn {
    margin: 10px;
  }

  .contact-items {
    margin-top: 1.5rem;
  }

  .contact-items svg {
    fill: white;
    width: 30px;
    height: 30px;
    margin-right: 1rem;
  }

  .text-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-left h1 {
    font-size: 2rem;
    color: #24441e;
  }

  .text-left h2 {
    font-size: 1.5rem;
    color: #24441e;
  }

  .text-left h3 {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #24441e;
  }

  .text-left p {
    text-align: center;
    margin: 1.5rem;
  }

  .get-started p {
    text-align: center;
    margin: 0.5rem 0;
  }

  .image-right {
    display: none;
  }

  .large-screen-text {
    display: none;
  }

  .get-started {
    /* background-color: #fffef2; */
    background-color: #6eab2c20;
    padding: 1.5rem;
    border-radius: 30% 70% 50% 50% / 30% 30% 70% 70%;
    /* box-shadow: 2px 2px 5px #6eab2c;
    transition: box-shadow 250ms; */
    max-width: 400px;
  }
  /* 
  .get-started:hover {
    box-shadow: 3px 3px 5px #6eab2c;
  } */

  .contact-button {
    text-decoration: none;
    padding: 6px;
    margin-top: 0.5rem;
    border-radius: 10px;
    color: white;
    background-color: #6eab2c;
    transition: color, background-color 200ms ease-in-out;
  }

  .contact-button:hover {
    background-color: #24441e;
    /* color: #6eab2c; */
  }

  /* @media screen and (max-width: 800px) {

    height: 100vh;

  } */

  @media screen and (min-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    gap: 1rem;

    .image-right {
      margin: 1.5rem;
      /* background: red; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .large-screen-text {
      display: block;
    }

    .text-left {
      padding: 2rem;
    }

    .text-left p {
      text-align: left;
    }

    .text-left h1 {
      font-size: 2.5rem;
      color: #24441e;
    }

    .text-left h2 {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 1100px) {
    .image-right {
      display: none;
    }

    .large-screen-text {
      display: none;
    }

    .get-started {
    }
  }

  @media screen and (min-width: 1500px) {
    padding: 10rem;
    .get-started {
      margin-top: 1rem;
    }
  }
`;
